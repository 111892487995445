<template>
  <div>
    <q-select
      dense
      filled
      bg-color="blue-grey-1"
      :disable="Boolean(popupParam.userType)"
      v-model="pick"
      :options="options"
      label=""
      color="indigo-5"
      options-selected-class="text-deep-orange"
      class="user-pop-search-type"
    >
      <template v-slot:selected>
        
        <q-list dense>
          <q-item tag="label">
            <q-item-section avatar>
              <q-icon :name="pick.icon" class="text-deep-orange"></q-icon>
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-primary"><b>{{pick.label}}</b></q-item-label>
              <q-item-label caption>
                {{pick.description}}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </template>
      <template v-slot:option="scope">
        <q-item
          v-bind="scope.itemProps"
          v-on="scope.itemEvents"
        >
          <q-item-section avatar>
            <q-icon :name="scope.opt.icon"></q-icon>
          </q-item-section>
          <q-item-section>
            <q-item-label v-html="scope.opt.label"></q-item-label>
            <q-item-label caption>{{ scope.opt.description }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div v-show="pick.value==='user'" class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div v-show="pick.value==='user'" class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" name="deptCd" :plantCd="plantCd" v-model="searchParam.deptCd" />
        </div>
        <div v-show="pick.value==='vendor'" class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text
            readonly
            label="업체"
            name="vendorName"
            v-model="vendorName">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="userTable"
      title="사용자 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="id"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'user-vendor-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          deptCd: '',
          userType: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직무',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
        ],
        data: [],
        height: '500px'
      },
      searchParam: {
        plantCd: null,
        deptCd: null,
        userName: '',
        retireFlag: 'N'
      },
      pick: {
        value: 'vendor'
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    plantCd() {
      return this.searchParam.plantCd || this.popupParam.plantCd
    },
    options() {
      let val = [
        {
          label: '현업 직원 조회',
          value: 'user',
          description: '현업 내부 직원을 조회 할 수 있습니다.',
          icon: 'admin_panel_settings',
        },
        {
          label: this.$store.getters.user.vendorName + ' 업체 직원 조회',
          value: 'vendor',
          description: '로그인한 업체의 직원을 조회 할 수 있습니다.',
          icon: 'engineering',
        },
      ];
      return val;
    },
    vendorName() {
      return this.$store.getters.user.vendorName
    }
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
    pick() {
      this.grid.data = [];
      this.$refs['userTable'].$refs['compo-table'].clearSelection();
      if (this.pick.value === 'user') {
        this.listUrl = selectConfig.mdm.user.list.url;
        this.grid.columns = [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직무',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
        ]
      } else {
        this.listUrl = selectConfig.mdm.cim.vendor.user.list.url;
        this.grid.columns = [
          {
            name: 'personName',
            field: 'personName',
            label: '성명',
            align: 'center',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            style: 'width:90px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'sexName',
            field: 'sexName',
            label: '성별',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
        ]
      }

      this.getList();
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.cim.vendor.user.list.url;
      // code setting
      // list setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      } 
      if (this.popupParam.deptCd) {
        this.searchParam.deptCd = this.popupParam.deptCd;
      }
      if (this.options && this.options.length > 0) {
        if (!this.popupParam.userType) {
          this.pick = this.$_.find(this.options, { value: 'vendor', });
        } else {
          this.pick = this.$_.find(this.options, { value: this.popupParam.userType, });
        }
      }
      setTimeout(() => {
        // 초기 pick 정보가 변경되는 시점에 맞추어 listUrl이 결정됨에 따라 0.1초 여유시간을 둠
        this.getList();
      }, 100);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['userTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '사용자를 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
